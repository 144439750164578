export * as themes from "./themes";

export const SECTION_PREFIX = "section";

export const projectCategories = [
  "All",
  "Web",
  "Operating Systems",
  "Computer Graphics",
  "Other",
];

export const projectCategoryAll = "All";
